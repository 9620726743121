import "../App.css";
import Cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import React from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { layout, default_stylesheet, stylesheetOnNodeTap, stylesheetOnEdgeTap } from '../resources/styling'
Cytoscape.use( klay );

class GraphView extends React.Component {

    constructor(props) {
        super(props);
        this.setupCytoscapeCallbacks = this.setupCytoscapeCallbacks.bind(this);
        this.handleNodeTap = this.handleNodeTap.bind(this);
        this.handleEdgeTap = this.handleEdgeTap.bind(this);
        this.cy = React.createRef();

        this.state = {
            selectedNode: null, 
            selectedEdge: null, 
            graph: <CytoscapeComponent
                    id='covid-graph'
                    elements = {this.props.elements}
                    style={ { width: '100%', height: '80vh' } }
                    stylesheet={default_stylesheet}
                    layout = {layout}
                    cy={(cy) => { this.setupCytoscapeCallbacks(cy) }}
                />
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.elements !== this.props.elements) {
            this.setState(
                {graph : <CytoscapeComponent
                    id='covid-graph'
                    elements = {this.props.elements}
                    style={ { width: '100%', height: '80vh' } }
                    stylesheet={default_stylesheet}
                    layout = {layout}
                    cy={(cy) => { this.setupCytoscapeCallbacks(cy) }}
                />}
            )
        }
    }

    handleNodeTap(evt) {
        let node = evt.target;
        let tappedNodeData = node._private.data;
        let tappedNodeAdjEdges = node._private.edges.map((elt) => (elt._private.data));

        this.cy.style(stylesheetOnNodeTap(tappedNodeData.id, tappedNodeData.type, tappedNodeAdjEdges));
        this.props.handleNodeTap(tappedNodeData);
    }

    handleEdgeTap(evt) {
            let edge = evt.target;
            let tappedEdgeData = edge._private.data;
            this.setState({selectedEdge: edge});
            this.cy.style(stylesheetOnEdgeTap(tappedEdgeData));
            this.props.handleEdgeTap(tappedEdgeData);
    }

    setupCytoscapeCallbacks(cy) {
        this.cy = cy;
        this.cy.layout(layout).run();

        this.cy.on('tap', 'node', this.handleNodeTap);
        this.cy.on('tap', 'edge', this.handleEdgeTap);
    }

    render() {
        if (this.props.elements && this.props.elements.length===0) { 
            alert("No elements are in the graph. Please reset the graph.");
        }
        return (
            this.state.graph
        );
    }
}

export default GraphView