import "../App.css";
import React from 'react';
import getServerURL from '../utils';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button'
import { BsChevronRight } from 'react-icons/bs';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
const verb_color = 'lightblue';
const subj_color =  'lightgreen';
const obj_color = 'pink';
const ServerURL = getServerURL();

class InfoView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentenceElements: null
        }
        this.getSentences = this.getSentences.bind(this);
    }

    componentDidMount() {
        this.getSentences(this.props.selectedEdge);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedEdge !== prevProps.selectedEdge || this.props.selectedNode !== prevProps.selectedNode) {
            this.getSentences(this.props.selectedEdge);
        }
    }

    //helper function to sort starting, ending points
    compare(a, b) {
        if (a.start > b.start) {
            return 1;
        } else if (a.start < b.start) {
            return -1;
        } else {
            if (a.end < b.end) {
              return -1;
            } else if (a.end > b.end) {
              return 1;
            }
            return 0;
        }
    }

    // helper function to help build parts of sentences
    constructSentenceTooltips(elt) {
        switch (elt.type) {
            case "verb":
                return (
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip key={elt.type}>{elt.ents}</Tooltip>}
                    >
                        <span style={{'backgroundColor': verb_color}}>
                            {elt.text}
                        </span>
                    </OverlayTrigger>
                )
            case "subj":
                return (
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip key={elt.type}>{elt.ents}</Tooltip>}
                    >
                        <span style={{'backgroundColor': subj_color}}>
                            {elt.text}
                        </span>
                    </OverlayTrigger>
                )
            case "obj":
                return (
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip key={elt.type}>{elt.ents}</Tooltip>}
                    >
                        <span style={{'backgroundColor': obj_color}}>
                            {elt.text}
                        </span>
                    </OverlayTrigger>
                )
            default:
                return (
                    <span>
                        {elt.text}
                    </span>
                )
        }
    }

    getSentences(selectedEdge) {
        if (selectedEdge) {
            let source = selectedEdge.source;
            let target = selectedEdge.target;
            let label = selectedEdge.label;

            if (selectedEdge.evidence_source != 'CORD-19') {
                return;
            }

            let query = `/triple_evidence?source=${encodeURIComponent(source)}&target=${encodeURIComponent(target)}&label=${encodeURIComponent(label)}&num_texts=20`;

            fetch(ServerURL + query)
                .then((res) => res.json())
                .then(
                    (data) => {
                        let sentenceElements = data.map(
                            (elt, idx) => {
                                let text = elt.text;
                                let doc_id = elt.doc_id;
                                let title = elt.title;
                                let authors = elt.authors;
                                let publish_time = elt.publish_time;
                                let journal = elt.journal;
                                let journal_info = elt.journal_info;

                                let subj_start = elt.start1;
                                let subj_end = elt.end1;
                                let subj_ents = elt.type1;
                                let obj_start = elt.start2;
                                let obj_end = elt.end2;
                                let obj_ents = elt.type2;
                                let verb_start = elt.startr;
                                let verb_end = elt.endr;
                                let verb_text = label;

                                let sortedTags = [
                                    {type:"verb", start: verb_start, end: verb_end, ents: verb_text},
                                    {type:"subj", start: subj_start, end: subj_end, ents: subj_ents},
                                    {type:"obj", start: obj_start, end: obj_end, ents: obj_ents},
                                ]
                                sortedTags = sortedTags.sort(this.compare);
                                
                                // partition sentence - normal is non subject, object, or verb
                                let brokenSentence = [
                                    {'type': 'normal', 'text': text.substring(0, sortedTags[0].start)},
                                    {'type':  sortedTags[0].type, 'text': text.substring(sortedTags[0].start, sortedTags[0].end), 'ents': sortedTags[0].ents},
                                ]

                                if (sortedTags[0].end < sortedTags[1].start) {
                                    brokenSentence.push(
                                        {'type': 'normal', 'text': text.substring(sortedTags[0].end, sortedTags[1].start)},
                                        {'type':  sortedTags[1].type, 'text': text.substring(sortedTags[1].start, sortedTags[1].end), 'ents': sortedTags[1].ents}
                                    );
                                } else {
                                    brokenSentence[brokenSentence.length - 1]['ents'] += ";" + sortedTags[1].ents;
                                }

                                if (sortedTags[1].end < sortedTags[2].start) {
                                    brokenSentence.push(
                                        {'type': 'normal', 'text': text.substring(sortedTags[1].end, sortedTags[2].start)},
                                        {'type':  sortedTags[2].type, 'text': text.substring(sortedTags[2].start, sortedTags[2].end), 'ents': sortedTags[2].ents}
                                    );
                                } else {
                                    brokenSentence[brokenSentence.length - 1]['ents'] += ";" + sortedTags[2].ents;
                                }

                                brokenSentence.push({'type': 'normal', 'text': text.substring(sortedTags[2].end)});

                                let metadataPopover = (
                                    <Popover>
                                        <Popover.Content>
                                            <p><strong>ID: </strong> {doc_id}</p>
                                            <p><strong>Title: </strong> {title}</p>
                                            <p><strong>Authors: </strong> {authors}</p>
                                            <p><strong>Publish Date: </strong> {publish_time}</p>
                                            <p><strong>Journal: </strong> {journal}</p>
                                            <p><strong>Journal Impact Factor: </strong> {journal_info}</p>
                                        </Popover.Content>
                                    </Popover>
                                )

                                return (
                                    <div key={idx}>
                                        <hr/>
                                        <div className="p-1">
                                        { 
                                            brokenSentence.map((elt, idx) => {
                                              return <span key={idx}>{this.constructSentenceTooltips(elt)}</span>;
                                            })
                                        }
                                        </div>
                                        <OverlayTrigger 
                                            trigger='click'
                                            placement='bottom'
                                            overlay={metadataPopover}    
                                        >
                                            <div className="d-flex mt-3">
                                                <Button variant="link" size="sm" className="text-dark">
                                                    <small className="mr-3">Paper details</small><BsChevronRight/>
                                                </Button>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                )
                            }
                        )
                        this.setState({
                            sentenceElements: <div>{sentenceElements}</div>
                        });
                    }
                ).catch((error) => {
                    alert("Backend error");
                    this.setState({
                        sentenceElements: <div></div>
                    });
              });
        }
    }

    render() {
        return (
            <div className="overflow-auto" style={ { width: '100%', height: '80vh' } }>
                { this.props.selectedEdge
                  ? (
                    <div>
                        <h3>{this.props.selectedEdge.id}</h3>
                        <small>Edge weight: {this.props.selectedEdge['weight']}</small>
                        <br></br>
                        <small>Evidence source: {this.props.selectedEdge['evidence_source']}</small>
                        {
                          this.props.selectedEdge.evidence_source == 'CORD-19'
                          ? (
                            <div>
                              <h6>Papers supporting the relationship (max 20 shown)</h6> 
                              <br/>
                              { 
                                  !this.state.sentenceElements ? (
                                      <Container>
                                          <Row>
                                              <Spinner animation="border" variant="primary" className="mr-3"/>
                                              <p>Loading supporting texts...</p>
                                          </Row>
                                      </Container>
                                  ) : 
                                  <div>
                                      { this.state.sentenceElements }
                                  </div>
                              }
                            </div>
                          )
                          : null
                        }
                    </div>
                  ) 
                  : (
                    this.props.selectedNode 
                    ? (
                        <div>
                            <h3>{this.props.selectedNode['id']}</h3>
                            <small><b>Identifiers:</b> {this.props.selectedNode['identifier']}</small><br/>
                            <small><b>Entity types:</b> {this.props.selectedNode['node_type']}</small><br/>
                            <small><b>Synonyms:</b> {this.props.selectedNode['info']}</small><br/>
                            <small><b>Degree:</b> {this.props.selectedNode['degree']}</small>
                        </div>
                    ) 
                    : null
                  )
                }
            </div>
        )
    }
}

export default InfoView;