// Color Constants
let default_font_size = 150;

let node_shape = 'ellipse';

let node_color = "lightblue";
let node_color_border = 'blue';

let edge_color = 'lightblue';
let edge_color_border = 'blue';

let community_color = "#e4e0ff";
let community_color_border = "#d500ff";

let following_color = 'pink';
let following_color_border = 'red';

let follower_color = 'lightgreen';
let follower_color_border = 'green';

let arrow_scale = 1;

// Background Stylesheet
let stylesheet_background = [
  {
    'selector': 'node[type="item"], node[type="cluster"]',
        'style': {
            'opacity': 0.4,
            'shape': node_shape,
            'label': 'data(label)',
            'font-size': 'data(text_size)',
            'width': 'data(node_size)',
            'height': 'data(node_size)',
        }
    }, 
    {
        'selector': 'edge',
        'style': {
            'opacity': 0.4,
            'curve-style': 'bezier',
            'control-point-step-size': 500,
            'width': 'data(edge_size)',
            'font-size': 'data(text_size)'
        }
    },
]

// Layout Information
var layout = {
    name: 'klay',
    nodeDimensionsIncludeLabels: true,
    // avoidOverlap: false,
    // radius: 2500,
};


// Default Stylesheet
var default_stylesheet =
    [
        {
            'selector': 'node[type="item"], node[type="cluster"]',
            'style': {
                'opacity': 0.7,
                'label': 'data(label)',
                'width': 'data(node_size)',
                'height': 'data(node_size)',
                'font-size' : 'data(text_size)',
            }
        },
        {
            'selector': 'edge',
            'style': {
                'curve-style': "bezier",
                'control-point-step-size': 500,
                'opacity': 0.65,
                'width': 'data(edge_size)',
                'font-size': 'data(text_size)',
                'target-arrow-shape': "vee",
                'arrow-scale': arrow_scale,
            }
        },
    ];

// Returns stylesheet when a node is tapped
function stylesheetOnNodeTap(id, type, adjEdges) {
    let stylesheet = stylesheet_background.slice();
    if (type === 'cluster' || type === 'item') {
        
        // the selected node
        stylesheet.push(
            {
                'selector': `node[id = "${id}"]`,
                'style': {
                    'background-color': node_color,
                    "border-color": node_color_border,
                    "border-width": 2,
                    "border-opacity": 1,
                    'opacity': 1,

                    'label': "data(label)",
                    'color': node_color_border,
                    "text-opacity": 1,
                    "font-size": 'data(text_size)',
                    'z-index': 5000,
                    'width': 'data(node_size)',
                    'height': 'data(node_size)', 
                } 
            }
        );

        // connected edges
        adjEdges.forEach( (elt) => {
                let srcId = elt.source;
                let tgtId = elt.target;
                let edgeId = elt.id;
                // edge pointing from selected node
                if (srcId === id) {
                    // target node
                    stylesheet.push({
                        'selector': `node[id = "${tgtId}"]`,
                        'style': {
                            "border-width": 2,
                            "border-opacity": 1,
                            "opacity": 1,

                            "label": "data(label)",
                            "color": following_color_border,
                            "text-opacity": 1,
                            "font-size": 'data(text_size)',
                            'width': 'data(node_size)',
                            'height': 'data(node_size)',            

                            'background-color': following_color,
                            'border-color': following_color_border,
                            'z-index': 4999,
                        }
                    })
                    //selected edge
                    stylesheet.push({
                        'selector': `edge[id= "${edgeId}"]`,
                        'style': {
                            'label': "data(label)",
                            "target-arrow-color": following_color,
                            "target-arrow-shape": "vee",
                            "arrow-scale": arrow_scale,
                            "line-color": following_color,
                            'opacity': 0.9,
                            'z-index': 9999,
                            'width': 'data(edge_size)',
                            "font-size": 'data(text_size)',
                        }
                    })
                } 
                // edge pointing towards selected node
                else if (tgtId === id) {
                    // source node
                    stylesheet.push({
                        "selector": `node[id = "${srcId}"]`,
                        "style": {

                            "border-width": 2,
                            "border-opacity": 1,
                            "opacity": 1,

                            "label": "data(label)",
                            "color": follower_color_border,
                            "text-opacity": 1,
                            "font-size": 'data(text_size)',

                            'background-color': follower_color,
                            'border-color': follower_color_border,
                            'z-index': 4999,
                            'width': 'data(node_size)',
                            'height': 'data(node_size)'
                        }
                    })
                    // edge
                    stylesheet.push({
                        "selector": `edge[id= "${edgeId}"]`,
                        "style": {
                            "label": "data(label)",
                            "target-arrow-color": follower_color,
                            "target-arrow-shape": "vee",
                            "arrow-scale": arrow_scale,
                            "line-color": follower_color,
                            'opacity': 1,
                            'z-index': 9999,
                            'width': 'data(edge_size)',
                            "font-size": 'data(text_size)',
                        }
                    })
                }

            }
        )
    } else if (type === 'parentCluster') {
        stylesheet.push({
            'selector': `node[id = "${id}"][type="parentCluster"]`,
            'style': {
                'background-color': community_color,
                'label': "data(label)",
                'font-size': 500,
                "border-color": community_color_border,
                "border-width": 2,
                "border-opacity": 1,
                'opacity': .5,
                'color': node_color_border,
                "text-opacity": 1,
                'z-index': 5000,
            }
        })
    }

    return stylesheet;
}

function stylesheetOnEdgeTap(edge) {
    let stylesheet = stylesheet_background.slice();

    let edgeId = edge.id;
    let srcId = edge.source;
    let tgtId = edge.target;

    // the selected edge
    stylesheet.push(
        {
            "selector": `edge[id = "${edgeId}"]`,
            "style": {
                "label": "data(label)",
                "target-arrow-color": edge_color_border,
                "target-arrow-shape": "vee",
                "arrow-scale": arrow_scale,
                "line-color": edge_color,
                'opacity': 0.9,
                'z-index': 9999,
                'width': 'data(edge_size)',
                "font-size": 'data(text_size)',
            }
        }
    ); 

    // target node
    stylesheet.push({
        "selector": `node[id = "${tgtId}"]`,
        "style": {
            "border-width": 2,
            "border-opacity": 1,
            "opacity": 1,

            "label": "data(label)",
            "color": following_color_border,
            "text-opacity": 1,
            "font-size":'data(text_size)',
            'width': 'data(node_size)',
            'height': 'data(node_size)',

            'background-color': following_color,
            'border-color': following_color_border,
            'z-index': 4999
        }
    });

    // source node
    stylesheet.push({
        "selector": `node[id = "${srcId}"]`,
        "style": {
            "border-width": 2,
            "border-opacity": 1,
            "opacity": 1,

            "label": "data(label)",
            "color": follower_color_border,
            "text-opacity": 1,
            "font-size": 'data(text_size)',
            'width': 'data(node_size)',
            'height': 'data(node_size)',

            'background-color': follower_color,
            'border-color': follower_color_border,
            'z-index': 4999
        }
    })

    return stylesheet;
}

export {layout, default_stylesheet, stylesheetOnNodeTap, stylesheetOnEdgeTap};