import "../App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import debounce from 'lodash/debounce';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { BsChevronDown } from 'react-icons/bs';
import { HashLink as Link } from 'react-router-hash-link';

import GraphView from '../components/GraphView';
import ListView from '../components/ListView';
import SearchView from '../components/SearchView';
import InfoView from '../components/InfoView';
import Spinner from 'react-bootstrap/Spinner';

import getServerURL from '../utils';

const ServerURL = getServerURL();

const noElementsFoundMessage = "No elements could be found in the knowledge graph.";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.data = React.createRef();
        this.state = {
            elements: null, 
            graphView: true, // list view if false
            searchView: true, // info view if false
            selectedEdge: null,
            selectedNode: null,
        };

        this.handleReset = this.handleReset.bind(this);
        this.handleChangeGraphView = this.handleChangeGraphView.bind(this);
        this.handleChangeSearchView = this.handleChangeSearchView.bind(this);
        this.handleUpdateElements = this.handleUpdateElements.bind(this);
        this.handleNodeTap = this.handleNodeTap.bind(this);
        this.handleEdgeTap = this.handleEdgeTap.bind(this);
    }

    componentDidMount() {
        this.handleUpdateElements('/original_elements');
    }

    handleReset() {
        this.setState({
            elements: null, 
            graphView: true, // list view if false
            searchView: true, // info view if false
            selectedEdge: null,
            selectedNode: null,
        });
        this.handleUpdateElements('/original_elements');
    }

    handleChangeGraphView(event) {
        if (event.target.name === "graphViewButton") {
            this.setState({
                graphView: true
            })
        } else if (event.target.name === "listViewButton") {
            this.setState({
                graphView: false
            })
        }
    }

    handleChangeSearchView(event) {
        if (event.target.name === "searchViewButton") {
            this.setState({
                searchView: true
            })
        } else if (event.target.name === "infoViewButton") {
            this.setState({
                searchView: false
            })
        }
    }

    handleUpdateElements(query) {
        const oldElements = this.state.elements;
        this.setState({
            elements: null
        });
        fetch(ServerURL + query)
        .then((value) => value.json())
        .then((data) => {
            if (data && data.length > 0) {
                this.setState({
                    elements: data
                }); 
            } else {
                alert(noElementsFoundMessage);
                if (oldElements !== null) {
                    this.setState({
                        elements: oldElements,
                    }); 
                } else {
                  this.setState({
                      elements: [],
                  }); 
                }
            }
        }).catch((error) => {
            alert("Backend error");
            if (oldElements !== null) {
                this.setState({
                    elements: oldElements,
                }); 
            } else {
              this.setState({
                  elements: [],
              }); 
            }
        });
    }

    handleNodeTap(nodeData) {
        this.setState({
            selectedNode: nodeData,
            selectedEdge: null,
            searchView: false,
        });
    }

    handleEdgeTap(edgeData) {
        // update the papers here
        this.setState({
            selectedEdge: edgeData,
            selectedNode: null,
            selectedNodeInfo: null,
            searchView: false,
        });
    }

    render() {
        let infoViewButton = (this.state.elements || !this.state.selectedEdge) ? (
            <Button disabled variant="link" name="infoViewButton" className={!this.state.searchView ? 'text-dark font-weight-bold' : 'text-muted font-weight-normal'} onClick={this.handleChangeSearchView}>Info</Button> 
        ) : (
            <Button variant="link" name="infoViewButton" className={!this.state.searchView ? 'text-dark font-weight-bold' : 'text-muted font-weight-normal'} onClick={this.handleChangeSearchView}>Info</Button>
        )
        let listViewButton = <Button variant="link" name="listViewButton" className={!this.state.graphView ? 'text-dark font-weight-bold' : 'text-muted font-weight-normal'} onClick={this.handleChangeGraphView}>List view</Button>
        return (
            <div>
                <div id="home">
                    <h1 className='header text-primary'>Welcome to the COVID-19 Knowledge Graph</h1>
                    <p>This is a knowledge graph constructed using abstracts from the <a href="https://www.kaggle.com/allen-institute-for-ai/CORD-19-research-challenge">CORD-19 Kaggle dataset </a>as well as the following tools and sources/databases/ontologies:</p>
                    <p><a href='https://semrep.nlm.nih.gov/'>SemRep</a></p>
                    <p><a href='https://www.dgidb.org/'>Drug Gene Interaction Database</a></p>
                    <p><a href=' https://www.disgenet.org/home/'>DisGeNET</a></p>
                    <p><a href='https://hpo.jax.org/app/'>Human Phenotype Ontology</a></p>
                    <p><a href='https://string-db.org/'>STRING protein-protein interaction database</a></p>
                    <p><a href='https://www.uniprot.org/'>Uniprot</a></p>
                    <p><a href='http://geneontology.org/'>Gene Ontology</a></p>
                    <p><a href='https://www.genenames.org/'>HGNC</a></p>
                    <p><a href='https://disease-ontology.org/'>Disease Ontology</a></p>
                    <p><a href='https://www.nlm.nih.gov/research/umls/index.html'>UMLS</a></p> 
                    <p>Zoom in on the graph by using the scroll wheel and drag to pan across the graph. Click on nodes or edges to examine them in more detail in the Info tab. A small preview of the knowledge graph is shown below, but use the Search tab to the right to further explore the graph.</p>
                    <b>This graph was created by the Wang Genomics Lab at the Children's Hospital of Philadelphia for research and development purposes only. The website is currently under development; apologies for any bugs you may encounter.</b>
                </div>

                <div className="d-flex justify-content-center">
                    <Link smooth to="#data"><BsChevronDown className="mx-auto mt-5 mb-5" size="5em"/></Link>
                </div>
                
                <Container fluid id="data" className="pt-3" ref={this.data}>
                    <Row>
                        <Col xs={12} md={8}>
                            <Row className="p-3">
                                <Button variant="link" name="graphViewButton" className={this.state.graphView ? 'text-dark font-weight-bold' : 'text-muted font-weight-normal'} onClick={this.handleChangeGraphView}>Graph view</Button>
                                {listViewButton}
                                
                                <div className="ml-auto">
                                    <Button variant="outline-success" className="ml-1" onClick={this.handleReset}>Reset</Button>
                                </div>
                            </Row>
                            <div className='border border-secondary rounded p-3'>
                                { 
                                    !this.state.elements ? (
                                        <Container>
                                            <Row>
                                                <Spinner animation="border" variant="primary" className="mr-3"/>
                                                <p>Loading graph data...</p>
                                            </Row>
                                        </Container>
                                    ) : null 
                                }
                                { 
                                    this.state.elements && this.state.graphView ? 
                                    <GraphView 
                                        elements={this.state.elements}
                                        handleNodeTap={debounce(this.handleNodeTap, 100)}
                                        handleEdgeTap={debounce(this.handleEdgeTap, 100)}
                                    /> : null
                                }
                                {
                                    this.state.elements && ! this.state.graphView ?
                                    <ListView
                                        elements={this.state.elements}
                                        handleEdgeTap={this.handleEdgeTap}
                                        handleNodeTap={this.handleNodeTap}
                                    /> : null
                                }
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row className="p-3">
                                <Button variant="link" name="searchViewButton" className={this.state.searchView ? 'text-dark font-weight-bold' : 'text-muted font-weight-normal'} onClick={this.handleChangeSearchView}>Search</Button>
                                {infoViewButton}
                            </Row>
                            <div className='border border-secondary rounded p-3'>
                                {
                                    this.state.searchView ?
                                    <SearchView
                                        handleUpdateElements={this.handleUpdateElements}
                                    />
                                    : <InfoView
                                        selectedEdge={this.state.selectedEdge}
                                        selectedNode={this.state.selectedNode}
                                        selectedNodeInfo={this.state.selectedNodeInfo}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>

                <footer className="container clear-bottom mt-5">
                <p className='text-center p-0 m-0 copyright'>All Rights Reserved @ Wang Genomics Lab 2010-2021</p>
                </footer>
            </div>
        );
    }
}

export default Home;
