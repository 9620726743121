import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { MdInfo } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Figure1 from "../images/figure1_updated.png";
import use_case_1 from "../images/use_case_1.png";
import use_case_2 from "../images/use_case_2.png";
import use_case_3 from "../images/use_case_3.png";
import use_case_3_shortest_path from "../images/use_case_3_shortest_path.png";

import  { dataSources } from '../resources/constants';
import data from "../resources/weights.json";
import { Histogram, DensitySeries, BarSeries, withParentSize, XAxis, YAxis } from '@data-ui/histogram';

const ResponsiveHistogram = withParentSize(({ parentWidth, parentHeight, ...rest}) => (
    <Histogram
      width={parentWidth}
      height={parentHeight}
      {...rest}
    />
));

class Tutorial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evidenceSourceInput: dataSources[0],
        }
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
    }

    handleFormInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    render() {
        return(
            <Container>
                <h1 className='header text-primary'>Tutorial</h1>
                KGEV (Knowledge Graph Exploration and Visualization) is a web framework for expediting knowledge acquisition using Knowledge Graph. 

                <h5 className='mt-3'>Background</h5>
                Knowledges graphs (KGs) serve as a convenient framework for structuring knowledge. A number of computational methods have been developed to generate KGs from biomedical literature and use them for downstream tasks such as link prediction and questioning and answering. However, there is a lack of computational tools or web frameworks to support the exploration and visualization of the KG themselves, which would facilitate interactive knowledge discovery and formulation of novel biological hypotheses.

                <h5 className='mt-3'>Method</h5> 
                We developed a web framework for Knowledge Graph Exploration and Visualization (KGEV), to construct and visualize KGs in five stages: triple extraction, triple filtration, metadata preparation, knowledge integration, and graph database presentation. The application has convenient user interface tools such as node and edge search and filtering, data source filtering, neighborhood retrieval, shortest path calculation, by querying a backend graph database. Unlike other KGs, our framework allows fast retrieval of relevant texts supporting the relationships in the KG, thus allowing human reviewers to judge the reliability of the knowledge extracted.
 
                <h5 className='mt-3'>Results</h5>
                We demonstrated a case study of using the KGEV framework to perform research on COVID-19. The COVID-19 pandemic resulted in an explosion of relevant literature, making it challenging to make full use of the vast and heterogenous sources of information. We generated a COVID-19 KG with heterogenous information, including literature information from the CORD-19 dataset, as well as other existing knowledge from eight data sources. We showed the utility of KGEV in three intuitive case studies to explore and query knowledge on COVID-19. A demo of this web application can be accessed at http://covid19nlp.wglab.org. Finally, we also demonstrated a turn-key adaption of the KGEV framework to study clinical phenotypic presentation of human diseases, illustrating the versatility of the framework.

                <h5 className='mt-3'>Conclusion</h5>
                In an era of literature explosion, the KGEV framework can be applied to many emerging diseases to support structured navigation of the vast amount of newly published biomedical literature and other existing biological knowledge in various databases. It can be also used as a general-purpose tool to explore and query gene-phenotype-disease-drug relationships interactively.

                <p className='mt-3'>An overview of the KGEV pipeline is shown in the figure below:</p>

                <img src={Figure1} height='500px'/>

                <p className='mt-5'>This tutorial is designed to demonstrate how to use the KGEV web application's search functionalities to replicate the different use cases/examples given in the manuscript.</p>

                <h3 className='mt-3'>Use case 1: direct search</h3>
                <p>The first use case is a direct search, that is, finding evidence that directly mentions a relationship between two entities. In this case, the user wants to retrieve texts mentioning a relationship between two known entities. For example, a user may want to find literature on the use of hydroxychloroquine to treat COVID-19. They can use the web application user interface to query the triple hydroxychloroquine -> TREATS -> COVID-19. The image below shows the search settings, highlighted by red arrows, used to perform the search. Here the search type is shortest paths search because it will return the direct relationship between the two entities if it exists, which direct search assumes. Only "CORD-19" is selected in the "Evidence search" section because it is the only data source with textual evidence.</p>
                <p>Upon clicking on the edge between the two nodes, the user interface shows texts where this relationship exists. The idea is that the supporting texts provide a brief summary of the relationship, and the user can be redirected to the full articles through the metadata pop-up. The texts serve only to summarize where the triple was mentioned and retrieve relevant articles but may not provide the complete picture; a user can click “Paper details” and be redirected to read the full article in detail.</p>
                <img src={use_case_1} height='500px'/>

                <h3 className='mt-5'>Use case 2: neighborhood search</h3>
                <p>The second use case is a neighborhood search. Using neighborhood search, a user can obtain the neighborhood of a node, that is, all the nodes connected to the search node by an edge. Additionally, the neighborhood search can be run at a depth of two to include neighbors of neighbors. An example use case is if a user wants to explore possible treatments for COVID-19 generally rather than a specific drug. Using neighborhood search, the user can search for drugs that interact with genes that interact with ACE2, since SARS-CoV-2 uses ACE2 for host cell entry. Various filters in the user interface can be used to accomplish this search. The image below shows the search settings, highlighted by red arrows, used to perform the search. Briefly, the search is looking for genes that interact with ACE2 (immediate neighbors) and drugs that interact with these genes (neighbors of neighbors). The neighbors of ACE2 were filtered so they only represent genes that have a gene-gene relationship in STRING with an interaction score >= 700, representing high confidence interactions. The second layer of edges represent drug-gene interactions from DGIdb (interaction score >= 2) and CORD-19 (at least two sources of evidence).</p>
                <img src={use_case_2} height='500px'/>

                <h3 className='mt-5'>Use case 3: shortest paths search</h3>
                <p>The third and last type of search, shortest paths search, would be useful in a situation where a user is interested in understanding what entities mediate the relationship between two entities of interest. An example use case is to use the shortest paths search through the web application user interface to find the pathways/genes that are shared by obesity and COVID-19. The shortest path search function does not necessarily need to return exclusively the shortest path; it is also possible to return paths within a given length, for example, one to three edges long. An option for excluding direct paths is also available. Additionally, the nodes in the middle of the path can be filtered to look for certain types of mediators between the two entities of interest; in this example we only allow genes within the shortest path.</p>
                <p>The top image below shows the search settings, highlighted by red arrows, used to perform the search for paths with one to three edges between obesity and COVID-19. The search represents paths between obesity and COVID-19 through nodes under the UMLS semantic group of “Genes & Molecular Sequences”. The edges represent disease-gene relationships from DisGeNET and gene-phenotype relationships from HPO to obtain obesity-associated genes, and gene-gene relationships from STRING (interaction score >= 700, representing high confidence interactions) and CORD-19 (at least 7 supporting texts).</p>
                <p>The bottom image below shows the search settings for exclusively shortest paths, excluding direct paths, between obesity and COVID-19. The only difference between this search and the aforementioned search in the previous image is that the search type is “Shortest paths” instead of “All paths”, and direct paths are excluded. These search settings are highlighted by red arrows.</p>

                <img src={use_case_3} height='500px'/>
                <img src={use_case_3_shortest_path} height='500px'/>
                

                <h3 className="mt-3">Edge weight distributions of evidence sources</h3>

                Below is the distribution of edge weights for various evidence sources, which helps map the weights to the original scale for the different data sources and helps improve the user experience. The evidence source can be selected using the dropdown.

                <Form.Group controlId="evidenceSource" className="mt-3">
                    <Form.Label className="mr-3">Evidence source</Form.Label>
                    <OverlayTrigger
                        placement={"right"}
                        overlay={
                            <Tooltip>Type of evidence source to get edge weight histograpm of.</Tooltip>
                        }
                    >
                        <MdInfo/>
                    </OverlayTrigger>
                    <Form.Control 
                        name="evidenceSourceInput"
                        as='select'
                        onChange={this.handleFormInputChange}
                    >
                        {dataSources.map((elt, index) => <option key={index} value={elt}>{elt}</option>)}
                    </Form.Control>
                </Form.Group>
                <div style={{height: '500px'}}>
                    <ResponsiveHistogram
                        ariaLabel="My histogram of ..."
                        orientation="vertical"
                        cumulative={false}
                        normalized={false}
                        binCount={100}
                        valueAccessor={datum => datum}
                        binType="numeric"
                        renderTooltip={({ event, datum, data, color }) => (
                        <div>
                            <strong style={{ color }}>{datum.bin0} to {datum.bin1}</strong>
                            <div><strong>count </strong>{datum.count}</div>
                            <div><strong>cumulative </strong>{datum.cumulative}</div>
                            <div><strong>density </strong>{datum.density}</div>
                        </div>
                        )}
                    >
                        <BarSeries
                        animated
                        rawData={data[this.state.evidenceSourceInput]}
                        />
                        <XAxis />
                        <YAxis />
                    </ResponsiveHistogram>
                </div>
            </Container>
        );
    }
}

export default Tutorial