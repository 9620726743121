import React from 'react';
import Container from 'react-bootstrap/Container';

function About() {
    return(
        <Container>
            <h1 className='header text-primary'>About</h1>
            <p> This tool was made for research and development purposes by the following team at the Wang Genomics Lab at the Children's Hospital of Philadelphia using open-access data. </p>
            <ul>
            <li>Dr. Kai Wang</li>
            <li>Dr. Yunyun Zhou</li>
            <li>Jacqueline Peng</li>
            <li>David Xu</li>
            <li>Ryan Lee</li>
            <li>Siwei Xu</li>
            </ul>
            <p> To find out more about the lab and other projects, please visit our <a href='https://wglab.org/' target="_blank" rel="noopener noreferrer">lab website</a>.</p>
        </Container>
    );
}

export default About