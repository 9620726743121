import React from "react";
import { useTable, useSortBy } from "react-table";
import { BsArrowUpDown, BsArrowUp, BsArrowDown } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';

const Table = ({ columns, data, handleEdgeTap, handleNodeTap }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
    },
    useSortBy
  );
  
  const handleCellTap = (cell) => {
    if (cell.column.Header === "Source ") {
      handleNodeTap(cell.row.original.sourceNode.data);
    } else if (cell.column.Header === "Target ") {
      handleNodeTap(cell.row.original.targetNode.data);
    } else {
      handleEdgeTap(cell.row.original.edge.data);
    }
  }

  const renderCell = (cell) => {
    let text = "";
    if (cell.column.Header === "Source ") {
      text = cell.row.original.sourceNode.data.id;
    } else if (cell.column.Header === "Target ") {
      text = cell.row.original.targetNode.data.id;
    } else {
      text = cell.row.original.edge.data.label;
    }
    return text;
  }

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div style={{'textAlign':'center'}}>
                        {column.render("Header")}
                        <span>
                            {column.isSorted ? (column.isSortedDesc ? <BsArrowDown/> : <BsArrowUp/>) : <BsArrowUpDown/>}
                        </span>
                    </div>

                </th>
            ))}
            </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
                <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}>
                                    <Button 
                                        // onClick={() => handleEdgeTap(cell.row.original)}
                                        onClick={() => handleCellTap(cell)}
                                        style={{"width":"100%"}}
                                        variant="link"
                                        className='text-muted font-weight-normal'
                                    >
                                        {renderCell(cell)}
                                    </Button>
                                </td>
                            );
                        })}
                </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;