import "../App.css";
import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

class CustomNavbar extends React.Component {

    render() {
        return (
            <div>
                <Navbar expand="lg" className="bg-white">
                    <Navbar.Brand href="/" className="text-primary">COVID-19 Knowledge Graph</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/" className="text-dark">Graph</Nav.Link>
                            <Nav.Link href="/about" className="text-dark">About</Nav.Link>
                            <Nav.Link href="/tutorial" className="text-dark">Tutorial</Nav.Link>
                        </Nav>
                    </Navbar.Collapse> 
                </Navbar>
                <hr/>
            </div>
        )
    }
}

export default CustomNavbar;
