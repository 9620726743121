
import "../App.css";
import React from 'react';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { MdInfo } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import Accordion from 'react-bootstrap/Accordion';

import  { nodeTypes, relationships, dataSources, searchOptions, edgeDirections } from '../resources/constants';


class SearchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nodeSearchInput: "",
            fuzzySearch: false,
            edgeSearchInput: "",
            edgeDirectionInput: "",
            edgeSearch2Input: "",
            edgeDirection2Input: "",
            nodeTypeInput: "",
            nodeType2Input: "",
            minPathLengthInput: 1,
            maxPathLengthInput: 3,
            searchTypeInput: 0,
            directPaths: true,
            evidenceSearchInputs: [],
            minEdgeWeightInputs: []
        }
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleMultipleOptionsChange = this.handleMultipleOptionsChange.bind(this);
        this.handleToggleFuzzySearch = this.handleToggleFuzzySearch.bind(this);
        this.handleToggleDirectPaths = this.handleToggleDirectPaths.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        for (var i = 0; i < dataSources.length; i++) {
            this.state.evidenceSearchInputs.push(true);
            this.state.minEdgeWeightInputs.push(0);
        }
        this.handleEvidenceSearchInputChange = this.handleEvidenceSearchInputChange.bind(this);
        this.handleMinEdgeWeightInputChange = this.handleMinEdgeWeightInputChange.bind(this);
    }

    handleEvidenceSearchInputChange(event) {
        let idx = event.target.name.split("-")[1];
        let arr = this.state.evidenceSearchInputs;
        arr[idx] = !arr[idx]
        this.setState({
            evidenceSearchInputs: arr
        });
    }

    handleMinEdgeWeightInputChange(event) {
        let idx = event.target.name.split("-")[1];
        let arr = this.state.minEdgeWeightInputs
        arr[idx] = parseInt(event.target.value);
        this.setState({
            minEdgeWeightInputs: arr
        });
    }

    handleFormInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleMultipleOptionsChange(event) {
      var options = event.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      this.setState({
        [event.target.name]: value
      });
    }

    handleToggleFuzzySearch() {
        this.setState({
            fuzzySearch: !this.state.fuzzySearch
        })
    }

    handleToggleDirectPaths() {
        this.setState({
            directPaths: !this.state.directPaths
        })
    }

    handleSearch(event) {
        event.preventDefault();
        let {
            nodeSearchInput,
            edgeSearchInput,
            edgeDirectionInput,
            edgeSearch2Input,
            edgeDirection2Input,
            nodeTypeInput,
            nodeType2Input,
            searchTypeInput,
        } = this.state;
        if (nodeSearchInput) {
            if (nodeTypeInput !== "") {
                nodeTypeInput = nodeTypeInput.join(";");
            }
            if (nodeType2Input !== "") {
                nodeType2Input = nodeType2Input.join(";");
            }
            if (edgeSearchInput !== "") {
                edgeSearchInput = edgeSearchInput.join(";");
            }
            if (edgeSearch2Input !== "") {
                edgeSearch2Input = edgeSearch2Input.join(";");
            }
            if ((searchTypeInput == 2 || searchTypeInput == 3) && (!nodeSearchInput.includes(";"))) {
                alert("At least two node terms must be entered to compute paths between them.");
                return;
            }
            let query = `/search_elements?`+
            `node_search=${encodeURIComponent(this.state.nodeSearchInput)}` + 
            `&edge_search=${encodeURIComponent(edgeSearchInput)}` + 
            `&edge_direction=${encodeURIComponent(edgeDirectionInput)}` + 
            `&edge_search_2=${encodeURIComponent(edgeSearch2Input)}` + 
            `&edge_direction_2=${encodeURIComponent(edgeDirection2Input)}` + 
            `&node_type=${encodeURIComponent(nodeTypeInput)}` + 
            `&node_type_2=${encodeURIComponent(nodeType2Input)}` + 
            `&fuzzy_search=${this.state.fuzzySearch}` + 
            `&search_type=${this.state.searchTypeInput}` + 
            `&min_path_length=${this.state.minPathLengthInput}` + 
            `&max_path_length=${this.state.maxPathLengthInput}` +
            `&direct_paths=${this.state.directPaths}`
            
            let evidenceSearchInput = []
            let minEdgeWeightInput = []
            for (var i = 0; i < dataSources.length; i++) {
                if (this.state.evidenceSearchInputs[i]) {
                    evidenceSearchInput.push(dataSources[i]);
                    minEdgeWeightInput.push(this.state.minEdgeWeightInputs[i] || 0);
                } 
            }
            evidenceSearchInput = evidenceSearchInput.join(";");
            minEdgeWeightInput = minEdgeWeightInput.join(";")

            query = query + 
            `&evidence_search=${evidenceSearchInput}` +
            `&min_edge_weight=${minEdgeWeightInput}` 

            this.props.handleUpdateElements(query);
        } else {
            alert("No node terms were passed to search.")
        }

    }

    render() {
        return (
            <div className="overflow-auto">
                <Form onSubmit={this.handleSearch} style={ { width: '100%', height: '80vh' } }>
                <Form.Group controlId="searchType">
                        <Form.Label className="mr-3">Search type</Form.Label>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>Type of search to perform.</Tooltip>
                            }
                        >
                            <MdInfo/>
                        </OverlayTrigger>
                        <Form.Control 
                            name="searchTypeInput"
                            as='select'
                            onChange={this.handleFormInputChange}
                        >
                            {searchOptions.map((elt, index) => <option key={index} value={index}>{elt}</option>)}
                        </Form.Control>
                    </Form.Group>

                    {
                      this.state.searchTypeInput == 2
                      ? (
                        <Row className="ml-1">
                          <Form.Check 
                              type="switch"
                              id="exclude-direct-paths"
                              label="Exclude direct paths"
                              className='mr-3'
                              onChange={this.handleToggleDirectPaths}
                          />
                          <OverlayTrigger
                              placement={"right"}
                              overlay={
                                  <Tooltip>Exclude direct paths between nodes (i.e. only return paths with length greater than 1).</Tooltip>
                              }
                          >
                              <MdInfo/>
                          </OverlayTrigger>
                      </Row>
                      )
                      : null
                    }

                    {
                      this.state.searchTypeInput == 3 
                      ? (
                        <div>
                            <Form.Group controlId="minPathLength">
                                <Form.Label className="mr-3">Minium path length</Form.Label>
                                <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                        <Tooltip>Minium path length between nodes.</Tooltip>
                                    }
                                >
                                    <MdInfo/>
                                </OverlayTrigger>
                                <Form.Control 
                                    type="number"
                                    name="minPathLengthInput" 
                                    value={this.state.minPathLengthInput}
                                    min='1'
                                    onChange={this.handleFormInputChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="maxPathLength">
                                <Form.Label className="mr-3">Maximum path length</Form.Label>
                                <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                        <Tooltip>Maximum path length between nodes.</Tooltip>
                                    }
                                >
                                    <MdInfo/>
                                </OverlayTrigger>
                                <Form.Control 
                                    type="number"
                                    name="maxPathLengthInput" 
                                    value={this.state.maxPathLengthInput}
                                    min='1'
                                    onChange={this.handleFormInputChange}
                                />
                            </Form.Group>
                        </div>
                      )
                      : null
                    }
                    <hr/>

                    <Form.Group controlId="nodeSearch">
                        <Form.Label className="mr-3">Node search</Form.Label>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>Enter nodes/entities to search for. Multiple search terms can be entered if separated by ';'.</Tooltip>
                            }
                        >
                            <MdInfo/>
                        </OverlayTrigger>
                        <Form.Control 
                            type="text" 
                            name="nodeSearchInput"
                            onChange={this.handleFormInputChange} 
                            placeholder="ACE2" />
                        <Form.Text className="text-muted">
                            Use 'COVID-19', 'Coronavirus infections', or 'SARS-CoV-2' for the disease/virus, respectively. You can also enter <a href="https://uts.nlm.nih.gov/uts/umls/home">UMLS concept unique identifiers</a>. Entering free-text works best with fuzzy search.
                        </Form.Text>
                    </Form.Group>

                    <Row className="ml-1">
                        <Form.Check 
                            type="switch"
                            id="fuzzy-search-switch"
                            label="Fuzzy node search"
                            className='mr-3'
                            onChange={this.handleToggleFuzzySearch}
                        />
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>Toggles fuzzy search (approximate string matching) on the nodes/entities.</Tooltip>
                            }
                        >
                            <MdInfo/>
                        </OverlayTrigger>
                    </Row>

                    <Form.Group controlId="nodeType" className="mt-3">
                        <Form.Label className="mr-3">Node type filter</Form.Label>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>
                                  If using neighborhood search, neighboring nodes of search node will be filtered by the entity type(s).
                                  If using path search, all non-terminal nodes in the path will be filtered by the entity type(s).
                                </Tooltip>
                            }
                        >
                            <MdInfo/>
                        </OverlayTrigger>
                        <Form.Control 
                            name="nodeTypeInput"
                            as='select' multiple
                            style={{ height: 200 }}
                            onChange={this.handleMultipleOptionsChange}
                        >
                            {nodeTypes.map((elt, index) => <option key={index}>{elt}</option>)}
                        </Form.Control>
                    </Form.Group>
                    {
                      this.state.searchTypeInput == 1
                      ? (
                        <Form.Group controlId="nodeType2" className="mt-3">
                            <Form.Label className="mr-3">Node type filter 2</Form.Label>
                            <OverlayTrigger
                                placement={"right"}
                                overlay={
                                    <Tooltip>Neighboring nodes of neighboring nodes of search node will be filtered by the entity type(s).</Tooltip>
                                }
                            >
                                <MdInfo/>
                            </OverlayTrigger>
                            <Form.Control 
                                name="nodeType2Input"
                                as='select' multiple
                                style={{ height: 200 }}
                                onChange={this.handleMultipleOptionsChange}
                            >
                                {nodeTypes.map((elt, index) => <option key={index}>{elt}</option>)}
                            </Form.Control>
                        </Form.Group>
                      )
                      : null
                    }
                    <hr/>
                    <Form.Group controlId="edgeSearch">
                        <Form.Label className="mr-3">Edge type filter</Form.Label>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>
                                  If using neighborhood search, adjacent edges of the search nodes will be filtered by the relationship type(s).
                                  If using path search, all edges will be filtered by the relationship type(s).
                                </Tooltip>
                            }
                        >
                            <MdInfo/>
                        </OverlayTrigger>
                        <Form.Control 
                            name="edgeSearchInput"
                            as='select' multiple
                            style={{ height: 200 }}
                            onChange={this.handleMultipleOptionsChange}
                        >
                            {relationships.map((elt, index) => <option key={index}>{elt}</option>)}
                        </Form.Control>
                    </Form.Group>
                    {
                      this.state.searchTypeInput == 0 || this.state.searchTypeInput == 1
                      ? (
                        <Form.Group controlId="edgeDirection" className="mt-3">
                            <Form.Label className="mr-3">Edge direction filter</Form.Label>
                            <OverlayTrigger
                                placement={"right"}
                                overlay={
                                    <Tooltip>
                                      This is the direction with respect to the source node.
                                    </Tooltip>
                                }
                            >
                                <MdInfo/>
                            </OverlayTrigger>
                            <Form.Control 
                                name="edgeDirectionInput"
                                as='select'
                                onChange={this.handleMultipleOptionsChange}
                            >
                                {edgeDirections.map((elt, index) => <option key={index}>{elt}</option>)}
                            </Form.Control>
                        </Form.Group>
                        )
                        : null
                    }
                    {
                      this.state.searchTypeInput == 1
                      ? (
                        <div>
                            <Form.Group controlId="edgeSearch2">
                                <Form.Label className="mr-3">Edge type filter 2</Form.Label>
                                <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                        <Tooltip>
                                          Edges between the neighbors of the search node and their neighbors will be filtered by the relationship type(s).
                                        </Tooltip>
                                    }
                                >
                                    <MdInfo/>
                                </OverlayTrigger>
                                <Form.Control 
                                    name="edgeSearch2Input"
                                    as='select' multiple
                                    style={{ height: 200 }}
                                    onChange={this.handleMultipleOptionsChange}
                                >
                                    {relationships.map((elt, index) => <option key={index}>{elt}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="edgeDirection2" className="mt-3">
                            <Form.Label className="mr-3">Edge direction filter 2</Form.Label>
                                <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                        <Tooltip>
                                          This is the direction with respect to the neighbor node.
                                        </Tooltip>
                                    }
                                >
                                    <MdInfo/>
                                </OverlayTrigger>
                                <Form.Control 
                                    name="edgeDirection2Input"
                                    as='select'
                                    onChange={this.handleMultipleOptionsChange}
                                >
                                    {edgeDirections.map((elt, index) => <option key={index}>{elt}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        )
                        : null
                    }

                    <Accordion>
                        <Accordion.Toggle className="pl-0" as={Button} variant="link" eventKey="0">
                            Evidence search
                        </Accordion.Toggle>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>Select evidence sources to use and their respective mimum edge weights.</Tooltip>
                            }
                        >
                            <MdInfo/>
                        </OverlayTrigger>
                        <Accordion.Collapse eventKey="0">
                        <div>
                        { 
                            dataSources.map((elt, index) => 
                            <Form.Group key={index} controlId={`evidenceSearch-${index}`}>
                            <Form.Label>{elt}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Checkbox 
                                            checked={this.state.evidenceSearchInputs[index]}
                                            name={`evidenceSearchInput-${index}`} 
                                            onChange={this.handleEvidenceSearchInputChange}
                                        />
                                    </InputGroup.Prepend>
                                    <Form.Control  
                                        type="number"
                                        name={`minEdgeWeightInput-${index}`} 
                                        value={this.state.minEdgeWeightInputs[index]}
                                        min='0'
                                        onChange={this.handleMinEdgeWeightInputChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                        )}
                        </div>
                        </Accordion.Collapse>
                    </Accordion>

                    <div className="d-flex justify-content-center">
                        <Button variant="primary" type="submit" className='mt-3'>Search</Button>
                    </div>      
                </Form>
            </div>
            
        );
    }
}

export default SearchView;