import React from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';

let Err = () => {
    return(
        <Jumbotron>
            <h1>404 Page not found</h1>
            <hr/>
            <p> There doesn't seem to be a page here. Please return to the <a href="/"> home page</a>.</p>
        </Jumbotron>
    )
}

export default Err;
