export const nodeTypes = JSON.parse(process.env.REACT_APP_NODE_TYPES);

export const relationships = JSON.parse(process.env.REACT_APP_RELATIONSHIP_TYPES);

export const dataSources = JSON.parse(process.env.REACT_APP_EVIDENCE_SOURCES);

export const searchOptions = [
  "Neighborhood (depth 1)",
  "Neighborhood (depth 2)",
  "Shortest paths",
  "All paths"
];

export const edgeDirections = [
  "Any",
  "Out",
  "In",
];