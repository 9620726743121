import React from 'react';

// React package imports
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// Page and component imports
import Navbar from './components/CustomNavbar';
import About from './pages/About';
import Home from './pages/Home';
import Tutorial from './pages/Tutorial';
import Err from './pages/Error';

//import './App.css';

class App extends React.Component {

  render() {
    return (
      <div className="pl-5 pr-5">
        <Navbar/>
        <Switch>
          <Route exact path="/" component = {Home}/>
          <Route path="/about" component = {About}/>
          <Route path="/tutorial" component = {Tutorial}/>
          <Route path="*" component = {Err}/>
        </Switch>
      </div>
    );
  }
}

export default App;