import "../App.css";
import React from 'react';
import Table from './Table';
import { left } from "@popperjs/core";

class ListView extends React.Component {
    constructor(props) {
        super(props);
        this.getListElements = this.getListElements.bind(this);

        this.columns = [
            {
                Header:"Source ",
                accessor:"source",
                sortType: "basic",
            },
            {
                Header:"Relationship ",
                accessor:"label",
                sortType: "basic",
            },
            {
                Header:"Target ",
                accessor:"target",
                sortType: "basic",
            },
        ]
    }

    getListElements(elements) {
        const edges = elements.filter((elt) => elt.data.edge_size);
        const seen = new Set();
        const uniqueEdges = edges.filter(el => {
            const duplicate = seen.has(el.data.id);
            seen.add(el.data.id);
            return !duplicate;
        });

        let nodes = elements.filter((elt) => elt.data.node_size);

        // add nodes and edges together
        const data = uniqueEdges.map((edge, index) => {
            const sourceNode = nodes.find(x => x.data.id === edge.data.source);
            const targetNode = nodes.find(x => x.data.id === edge.data.target);
            return (
                {edge: edge, sourceNode: sourceNode, targetNode: targetNode}
            );
        }) 
        return data;
    }

    render() {
        const data = this.getListElements(this.props.elements);
        return (
            <div className="overflow-auto" style={ { width: '100%', height: '80vh' } }>
                <Table columns={this.columns} data={data} handleEdgeTap={this.props.handleEdgeTap} handleNodeTap={this.props.handleNodeTap}/>
            </div>
        );
    }
}

export default ListView;